import { api } from 'shared/core';
import axios from 'axios';

class PartnerAPI {
  /**
   * Получить список юр.лиц компании
   */
  async getCompanyPartyList() {
    return api.endpoint.get('/dashboard/company-party/get-list');
  }

  /**
   * Получить список расчестных счетов партнера
   */
  async getCompanyPartyAccountList({ party_id }) {
    return api.endpoint.get('/dashboard/company-party/account/get-list', { party_id });
  }

  /**
   * Получение списка документов (подписанных и нет)
   */
  getLegalDocs() {
    return api.endpoint.get('dashboard/utils/get-legal-docs');
  }

  getLegalDocTypes() {
    return api.endpoint.get('dashboard/utils/get-legal-doc-types');
  }

  /**
   * Подписать документ
   */
  setDocSigned(opts = {}) {
    const { legal_document_id } = opts;
    return api.endpoint.post('dashboard/utils/set-doc-signed', { id: legal_document_id });
  }

  /**
   * Получить выручку и доходы по отрезку времени
   * @param {{start: Date, end: Date, mode: string}} opts
   */
  async getIncome(opts = {}) {
    const { mode = 'days', start, end } = opts;
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);

    return api.endpoint.get('dashboard/stats/get-income', {
      timestamp_start: start.toISOString(),
      timestamp_end: end.toISOString(),
      mode,
    });
  }

  /**
   * Получить список клиентов партнера
   * @param {{page?: number, limit?: number, q?: string, order?: array, start: Date, end: Date}} opts
   */
  async getClients(opts = {}) {
    const { page = 1, limit = 20, q = '', order = ['name', 'ASC'], start, end } = opts;

    return api.endpoint.get('dashboard/related-company/get-list', {
      page,
      limit,
      q,
      [`order[${order[0]}]`]: order[1],
      timestamp_start: start.toISOString(),
      timestamp_end: end.toISOString(),
    });
  }

  /**
   * Получить список магазинов клиента
   * @param {{id: number}} opts
   */
  async getStores(opts = {}) {
    const { id } = opts;

    return api.endpoint.get('/dashboard/related-company/get-stores', { id });
  }

  /**
   * Добавить нового клиента
   * @param {{name: string}} opts
   */
  async addClient(opts = {}) {
    const { name, rate_id } = opts;

    return api.endpoint.post('/dashboard/related-company/create', { name, rate_id });
  }
  /**
   * Удалить клиента
   * @param {{id: number}} opts
   */
  async deleteClient(opts = {}) {
    const { id } = opts;

    return api.endpoint.post('/dashboard/related-company/delete', { id });
  }
  /**
   * Добавить нового сотрудника
   * @param {{first_name: string, phone: string, id: number, email: string}} opts
   */
  async addEmployee(opts = {}) {
    const { first_name, phone, company_id, email } = opts;

    return api.endpoint.post('/dashboard/company-employee/create', {
      company_id,
      first_name,
      phone,
      email,
    });
  }

  async getMainPageAnalytics(opts = {}) {
    return api.endpoint.get('/dashboard/get-main-page');
  }

  async getTariffs() {
    return api.endpoint.get('/dashboard/rate/get-list');
  }

  async loginAsPartner(opts = {}) {
    const { company_id } = opts;
    const url = `${api.endpoint.uri.origin}/api/authorization/company/v1/login-as-partner`;

    return api.endpoint.post('', { company_id }, { url });
  }

  getStatus(opts = {}) {
    const { token } = opts;
    return api.endpoint.get('/common/get-status', { token });
  }

  /**
   * Получить ссылку для настройки компании
   * @param {{company_id: number}} opts
   */
  async getLinkOnCompany(opts = {}, repeat = true) {
    const { company_id } = opts;
    const { refresh_token, access_token } = api.endpoint.getTokens();

    const getConfig = (token) => ({
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    try {
      const data = await axios.post(
        'https://backoffice.briskly.online/company-authorization/login-as-partner/',
        { company_id },
        getConfig(access_token),
      );

      return data;
    } catch (error) {
      if (!error.response || error.response.status !== 401) throw error;

      const res = await axios.post(api.endpoint.uri.refreshToken, {}, getConfig(refresh_token));

      api.endpoint.saveTokens(res.data.tokens);

      if (repeat) return this.getLinkOnCompany(opts, false);
    }
  }
}

export const partnerAPI = new PartnerAPI();
