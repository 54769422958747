import { partnerAPI } from '@/core/api/partner';
import router from '@/core/router';

// TODO: maybe remove isInitAlreadyBegin
const state = {
  isOnboardingReady: false,
  init: false,
  isInitAlreadyBegin: false,
  loading: true,
};

const getters = {
  stage: (state) => state.stage,
  init: (state) => state.init,
  isInitAlreadyBegin: (state) => state.isInitAlreadyBegin,
  loading: (state) => state.loading,
  isOnboardingReady: (state) => state.isOnboardingReady,
};

const actions = {
  // TODO: remove console.log
  init: async ({ commit, getters, dispatch, rootGetters }) => {
    IS_DEV && console.log('TRY INIT');

    if (getters.isInitAlreadyBegin) return;
    if (getters.init) return;

    commit('setIsInitAlreadyBegin', true);

    try {
      IS_DEV && console.log('RUN INIT');

      const docs = await partnerAPI.getLegalDocs();

      await dispatch('onboarding$onboarding/initDocsAction', docs, { root: true });

      if (rootGetters['onboarding$onboarding/getNextSignDoc']('onboarding')) {
        dispatch('onboarding$onboarding/setStageAction', 'agreement', { root: true });
        commit('setInit', true);
        return;
      }

      await dispatch('maybeShowPersonalOnboarding');
    } catch (error) {
      console.error('[ACTION] core$main/init', error);
      router.push('/logout');
    } finally {
      commit('setLoading', false);
      commit('setIsInitAlreadyBegin', false);
    }
  },

  maybeShowPersonalOnboarding: async ({ commit, dispatch }) => {
    const { items } = await partnerAPI.getCompanyPartyList();

    if (!items.length) {
      dispatch('onboarding$onboarding/setStageAction', 'personal', { root: true });
      commit('setInit', true);
      return;
    }

    let isOnboardingComplete = false;

    for (let i = 0; i < items.length; i++) {
      const id = items[i].id;
      const result = await partnerAPI.getCompanyPartyAccountList({ party_id: id });

      if (result.items.length) {
        isOnboardingComplete = true;
        break;
      }
    }

    isOnboardingComplete
      ? await dispatch('setOnboardingCompleteAction')
      : dispatch('onboarding$onboarding/setStageAction', 'personal', { root: true });

    commit('setInit', true);
  },

  setOnboardingCompleteAction: ({ commit }) => {
    commit('setOnboardingReady', true);
    router.push('/');
  },
};

const mutations = {
  setLoading: (state, value) => (state.loading = value),
  setInit: (state, value) => (state.init = value),
  setIsInitAlreadyBegin: (state, value) => (state.isInitAlreadyBegin = value),
  setOnboardingReady: (state, value) => (state.isOnboardingReady = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
