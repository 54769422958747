import { partnerAPI } from '@/core/api/partner';

const columnOrder = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'];
const intervalOrder = ['a_week', 'b_two_week', 'c_month', 'd_two_months', 'e_older'];

const state = {
  income: {
    items: [],
  },
  clients: {
    items: [],
    meta: {
      page: null,
    },
  },
  analytics: [],
  rawAnalytics: {},
  dayInterval: {
    range: { start: null, end: null },
    shortcut: 'today',
  },
};

const getters = {
  clients: (state) => state.clients.items,
  income: (state) => state.income.items.map((i) => ({ label: i.period, value: i.amount })),
  profit: (state) => state.income.items.map((i) => ({ label: i.period, value: i.profit })),
  dayInterval: (state) => state.dayInterval,
  analytics: (state) => state.analytics,
  rawAnalytics: (state) => state.rawAnalytics,
};

const actions = {
  getMainPageAnalytics: async ({ commit }, { expanded, token }) => {
    try {
      const response = expanded
        ? await partnerAPI.getStatus({ token })
        : await partnerAPI.getMainPageAnalytics();

      commit('_setAnalytics', response);
    } catch (error) {
      console.log(error);
    }
  },

  getClientsAction: async ({ state, commit }, opts = {}) => {
    try {
      const { q, order, start, end, page, merge = false } = opts;
      const { limit } = state.clients.meta;

      const clients = await partnerAPI.getClients({ limit, q, order, start, end, page });

      commit('setClients', { clients, merge });
    } catch (error) {
      console.error(error);
    }
  },

  loadMoreClientsAction({ state, dispatch }, opts) {
    const { page, total_pages } = state.clients.meta;
    if (page + 1 > total_pages) return;

    const { q, order, start, end } = opts;
    return dispatch('getClientsAction', { q, order, start, end, page: page + 1, merge: true });
  },

  getStoresAction: async (store, opts = {}) => {
    try {
      const { id } = opts;
      const stores = await partnerAPI.getStores({ id });

      return stores;
    } catch (error) {
      console.error(error);
    }
  },

  getIncomeAction: async ({ commit }, { start, end, mode }) => {
    try {
      const income = await partnerAPI.getIncome({ start, end, mode });
      commit('setIncome', income);
    } catch (error) {
      console.error(error);
    }
  },
  setDayIntervalAction: ({ commit }, data) => {
    commit('setDayInterval', data);
  },
  getPartyDataAction: (store, inn) => {
    return partnerAPI.getPartyData(inn);
  },
  createNewClientAction: async (store, { name, phone, email, rate_id }) => {
    const client = await partnerAPI.addClient({ name, rate_id });

    try {
      await partnerAPI.addEmployee({ first_name: 'Admin', company_id: client.id, phone, email });
    } catch (error) {
      partnerAPI.deleteClient({ id: client.id });
      throw error;
    }
  },
  deleteClientAction: (store, { id }) => {
    return partnerAPI.deleteClient({ id });
  },
  getLinkOnCompanyAction: (store, { company_id }) => {
    return partnerAPI.getLinkOnCompany({ company_id });
  },
};

const mutations = {
  _setAnalytics: (state, analytics) => {
    state.rawAnalytics = analytics;
    state.analytics = columnOrder
      .map((column) => {
        const items = intervalOrder
          .map((interval) => ({
            name: interval,
            items: analytics[column][interval] || [],
          }))
          .filter(({ items }) => items.length);

        return { name: column, items };
      })
      .filter(({ items }) => items.length);
  },

  setClients: (state, { clients, merge }) => {
    const { meta, items } = clients;

    state.clients.meta = meta;
    state.clients.items = merge ? [...state.clients.items, ...items] : items;
  },
  setIncome: (state, income) => {
    const { items } = income;
    const newItems =
      items.length === 1 ? [{ amount: 0, period: items[0].period, profit: 0 }, ...items] : items;

    state.income = { ...income, items: newItems };
  },
  setDayInterval: (state, { interval, shortcut }) => {
    state.dayInterval.range = interval;
    state.dayInterval.shortcut = shortcut;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
