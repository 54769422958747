import { partnerAPI } from '@/core/api/partner';

const state = {
  stage: '',
  loading: false,
  docs: { items: [], meta: {} },
  docOrder: [201, 204],
  createCompanyDocOrder: [203],
  unsignedDocs: [],
  currentDoc: 0,
};

const getters = {
  stage: (state) => state.stage,
  loading: (state) => state.loading,
  docs: (state) => state.docs,
  docOrder: (state) => state.docOrder,
  unsignedDocs: (state) => state.unsignedDocs,
  hasUnsignedDocs: (state) => !!state.unsignedDocs.length,
  currentDoc: (state) => state.currentDoc,
  getNextSignDoc: (state, getters) => (stage) => {
    let docType;

    let target;

    switch (stage) {
      case 'onboarding':
        target = state.docOrder;
        break;

      case 'create-company':
        target = state.createCompanyDocOrder;
        break;

      default:
        throw new Error('unknown target: ' + stage);
    }

    target.forEach((type) => {
      if (docType) return;

      const doc = getters.unsignedDocs.find((item) => {
        return !item.is_signed && item.legal_document.type === type;
      });

      if (doc) docType = doc;
    });

    return docType;
  },
};

const actions = {
  setStageAction: ({ commit }, value) => {
    commit('setStage', value);
  },

  initDocsAction: async ({ getters, dispatch, commit }, value) => {
    await dispatch('setDocsAction', value);

    const unsignedDocs = getters.docs.items.filter((doc) => !doc.is_signed);

    commit('setUnsignedDocs', unsignedDocs);
    commit('setCurrentDoc', getters.getNextSignDoc('onboarding'));
  },

  setDocsAction: ({ commit }, value) => {
    commit('setDocs', value);
  },

  signDocAction: async ({ getters, commit, dispatch }, opts = {}) => {
    const { doc, afterOnboarding = false } = opts;

    if (getters.loading) return;

    try {
      commit('setLoading', true);

      await partnerAPI.setDocSigned({ legal_document_id: doc.legal_document_id });

      commit('removeFromUnsignedDocs', doc);

      if (!afterOnboarding) {
        getters.getNextSignDoc('onboarding')
          ? commit('setCurrentDoc', getters.getNextSignDoc('onboarding'))
          : dispatch('core$main/maybeShowPersonalOnboarding', null, { root: true });
      }

      return true;
    } catch (error) {
      console.error('[ACTION] onboarding$onboarding/signDocAction', error);
    } finally {
      commit('setLoading', false);
    }
  },
};

const mutations = {
  setStage: (state, value) => {
    state.stage = value;
  },

  setLoading: (state, value) => {
    state.loading = value;
  },

  setDocs: (state, value) => {
    state.docs = value;
  },

  setUnsignedDocs: (state, value) => {
    state.unsignedDocs = value;
  },

  setCurrentDoc: (state, value) => {
    state.currentDoc = value;
  },

  setCurrentDoc: (state, value) => {
    state.currentDoc = value;
  },

  removeFromUnsignedDocs: (state, value) => {
    state.unsignedDocs = state.unsignedDocs.filter((v) => {
      if (v.legal_document.type === value.legal_document.type) return false;
      return true;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
