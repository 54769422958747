import 'animate.css';
import 'normalize.css';
import '@/core/styles.scss';
import 'regenerator-runtime';
import 'swiper/swiper-bundle.css'

import Vue from 'vue';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { api, bus, Channel, utils } from 'shared/core';
import { auth, personal } from 'shared/modules';
import App from '@/core/App';
import router from '@/core/router';
import store from '@/core/store';

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$bus = bus;
Vue.use(Vuex);
Vue.use(VueI18n);
Vue.use(VueAwesomeSwiper);

api.setEndpoint('business/partner');

utils.getEvents().forEach(({ value }) => value.init(store));
utils.getChannels().forEach(({ value }) => new Channel(value));

personal.connect();
auth.connect({ store, router, opts: { selectedCountry: 'ru', } });

new Vue({
  router,
  store,
  i18n: new VueI18n({
    locale: 'ru',
    messages: {},
  }),
  render: (h) => h(App),
}).$mount('#app');
