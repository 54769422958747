import { bus } from 'shared/core';

class MainEvent {
  store;

  init(_store) {
    this.store = _store;
    bus.on('authInit', this.onAuthInit);
  }

  onAuthInit = () => {
    // this.store.dispatch('core$main/init');
  };
}

export default new MainEvent();
