import Vue from 'vue';
import Router from 'vue-router';
import onboarding from '@/apps/onboarding/routes';

Vue.use(Router);

const routes = [
  {
    path: '/company-login/:id',
    name: 'login-as-partner',
    component: () => import('@/core/views/LoginAsPartner'),
  },

  {
    path: '/test/documents/:id',
    name: 'test-document',
    component: () => import('@/core/views/TestDocument'),
  },

  {
    path: '/unauth',
    name: 'unauth',
    component: () => import('@/core/views/Unauth'),
    children: [
      {
        path: 'status',
        name: 'status',
        props: (route) => ({ token: route.query.token, onlyAnalytics: true }),
        component: () => import('@/core/views/Main/Main'),
      },
    ],
  },

  {
    path: '/',
    component: () => import('@/core/views/Index'),
    children: [
      ...onboarding,

      {
        path: '',
        component: () => import('@/core/views/Main'),
        children: [
          {
            path: '',
            name: 'main',
            component: () => import('@/core/views/Main/Main'),
          },
        ],
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
});

export default router;
